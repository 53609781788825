<template>
    <section class="h-100">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal-listar h-100">
                <div class="row my-2 mx-3 align-items-center">
                    <div class="col pl-0 my-auto">
                        <el-input v-model="buscar" placeholder="Buscar pedido o cliente del pedido" size="small" clearable class="br-20 w-100" @input="busqueda" />
                    </div>
                    <el-tooltip class="item" effect="light" content="Filtro" placement="bottom">
                        <div class="btn-action border" @click="openFiltro">
                            <i class="icon-filter-outline f-18" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col px-0 mx-2 border br-10 listar-pedidos bg-white" style="width:400px;height:calc(100vh - 192px);">
                    <!-- listado pedidos -->
                    <div v-show="pedidos.length" class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-center">
                        <span>{{ paginate.total }} pedidos</span>
                    </div>
                    <div v-if="pedidos.length !== 0" class="overflow-auto custom-scroll" style="height: calc(100% - 118px);">
                        <div v-for="(ped, idx) in pedidos" :key="idx" class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2" @click="seleccionar_pedido(ped.id)">
                            <div :class="`mx-2 px-1 br-5 ${activo==ped.id?'bg-general':''}`" style="height:75px;" />
                            <div :class="`col-11 px-0 tres-puntos f-15 br-10 ${activo==ped.id?'bg-whitesmoke':''}`">
                                <div class="row mx-0">
                                    <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 px-2 pl-4 d-middle">
                                        <i v-show="[32,33,34].indexOf(ped.estado) > -1" class="icon-attention-alt text-danger f-18" />
                                        <p class="tres-puntos f-500 text-general">ID. {{ ped.id }}</p>
                                    </div>
                                    <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 px-1 tres-puntos text-right">
                                        <el-badge v-show="ped.cant_mensajes > 0" :value="ped.cant_mensajes" class="item mx-2 mt-2 mr-4">
                                            <i class="icon-message-text text-general2 hover-icons cr-pointer f-20" />
                                        </el-badge>
                                        <i :class="`f-18 ${icon_estado(ped.estado)}`" />
                                        {{ estado_pedido(ped.estado) }}
                                    </div>
                                </div>
                                <div class="row px-3 mx-0 my-1">
                                    <div class="col-xl-8 col-lg col-md-12 col-sm-12 col-12 px-2 text-general f-600">
                                        <p class="tres-puntos">{{ convertMoneyTendero(ped.valor_final,ped.idm_moneda) }}</p>
                                    </div>
                                    <div class="col-xl-4 col-lg col-md-12 col-sm-12 col-12 px-1 text-right">
                                        <b class="mr-1">
                                            {{ ped.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-3 mx-0">
                                    <div class="col-xl-8 col-lg col-md-12 col-sm-12 col-12 px-2 d-middle">
                                        <p class="tres-puntos text-general">{{ ped.direccion }}</p>
                                    </div>
                                    <div class="col-xl-4 col-lg col-md-12 col-sm-12 col-12 px-1 text-general">
                                        <b class="mr-1">
                                            {{ ped.distancia }} mts
                                        </b>
                                        aprox.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/clock.svg" alt="" />
                            </div>
                            <p>Aún no has recibidos, cancelado o rechazado pedidos</p>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-pagination
                        v-show="pedidos.length"
                        background
                        class="text-center paginar-general"
                        :current-page="paginate.current_page"
                        :page-size="paginate.per_page"
                        layout="prev, pager, next"
                        :total="paginate.total"
                        @current-change="listar_pedidos"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 d-none select-responsive py-2">
                <div class="row mx-0">
                    <div class="col-xl-3 col-lg-3 col-md-5 col-sm-4 col-sm-5">
                        <el-select
                        v-model="value"
                        class="w-100"
                        placeholder="Buscar pedido o cliente del pedido"
                        filterable
                        clearable
                        remote
                        :remote-method="busquedaSelect"
                        @change="seleccionar_pedido"
                        >
                            <el-option
                            v-for="(item, idx) in pedidos"
                            :key="idx"
                            :label="`ID ${item.id} - ${item.nombre}`"
                            :value="item.id"
                            style="height:78px;"
                            >
                                <div class="row mx-0 align-items-center">
                                    <div class="col text-general f-600">
                                        ID {{ item.id }}
                                    </div>
                                    <div class="col text-general">
                                        {{ convertMoneyTendero(item.valor_final,item.idm_moneda) }}
                                    </div>
                                    <div class="col-12 text-general">
                                        <el-badge v-show="item.cant_mensajes > 0" :value="item.cant_mensajes" class="item mx-2 mt-2 mr-4">
                                            <i class="icon-message-text text-general2 hover-icons cr-pointer f-20" />
                                        </el-badge>
                                        <i :class="`f-18 ${icon_estado(item.estado)}`" />
                                        {{ estado_pedido(item.estado) }}
                                    </div>
                                </div>
                            </el-option>
                        </el-select>
                        <p class="text-general f-13 pl-2">
                            <i>*Ultimos 10 pedidos*</i>
                        </p>
                    </div>
                </div>
            </div>
            <div class="px-0 border col bg-white br-t-10 menu-prinicipal-content ml-2 mr-2 mt-1" style="width: calc(100vw - 560px);">
                <listadoHistorial @update="listar_pedidos(1)" />
            </div>
        </div>
        <filtro-pedidos ref="modalFiltro" :opcion="3" @filtrar="accion_filtro" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    metaInfo: () =>({ title: 'Historial'}),
    name: 'IndexHistorialTendero',
    sockets: {
        admin_pedidos_chats(value){ // Emite Cuando se escribe en tabla admin_pedidos_chats
            try {
                const {chat, pedidoChat} = JSON.parse(value)
                if(this.$usuario.tienda.id === pedidoChat.id_tienda){ // Si el mensaje es de un pedido de este tendero
                    if(chat.mio !== 1  && this.id_pedido !== chat.id_admin_pedido){// cuando No tengo abierto  el chat del pedido actual entonces notifico
                        let idx_pedido = this.pedidos.findIndex(e => e.id === pedidoChat.id)
                        if(idx_pedido >= 0){
                            let found = this.pedidos[idx_pedido]
                            found.cant_mensajes = found.cant_mensajes +1
                        }
                        if(this.pedido && this.pedido.id === chat.id_admin_pedido){
                            this.pedido.cant_mensajes = this.pedido.cant_mensajes + 1
                        }
                    }
                }


            } catch (e){
                this.error_catch(e)
            }
        },
        // pedidos_reclamo(obj){
        //     let { id } = JSON.parse(obj)
        //     if (this.id_pedido == id){
        //         this.listar_pedidos(1)
        //         this.$store.commit('pedidos/pedidos_historial_tendero/set_id_pedido', null)
        //         this.$store.dispatch('pedidos/pedidos_historial_tendero/set_data')
        //     }
        // },
    },
    components: {
        listadoHistorial: () => import('./listadoHistorial'),
    },
    data(){
        return {
            visible:false,
            buscar: '',
            activo: -1,
            pagina_actual:1,
            estados_filtro:[],
            fecha_filtro:[],
            tipo_filtro:0,
            value: ''
        }
    },
    computed:{
        ...mapGetters({
            pedidos: 'pedidos/pedidos_historial_tendero/pedidos',
            paginate: 'pedidos/pedidos_historial_tendero/paginate',
            id_pedido: 'pedidos/pedidos_historial_tendero/id_pedido',// solo para actualizar icono chat
        }),
        rol(){
            return this.$usuario.rol_nombre
        }
    },
    mounted(){
        this.listar_pedidos(1)
    },
    methods: {
        busquedaSelect(t){
            this.buscar = t
            this.busqueda(t)
        },
        busqueda(text){
            const change = () => {
                if(text.length > 1){
                    this.listar_pedidos(1)
                }else if(text.length === 0){
                    this.listar_pedidos(1)
                    this.seleccionar_pedido(null)
                }
            }
            this.delay(change)
        },
        async listar_pedidos(page){
            try {
                this.pagina_actual = page

                let params = {
                    page:this.pagina_actual,
                    query:this.buscar,
                    estados:this.estados_filtro,
                    fecha:this.fecha_filtro,
                    tipo:this.tipo_filtro
                }
                await this.$store.dispatch('pedidos/pedidos_historial_tendero/listar_pedidos',params)
            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionar_pedido(id){
            this.activo = id
            this.$store.commit('pedidos/pedidos_historial_tendero/set_id_pedido', id)
        },
        icon_estado(estado){
            switch (estado){
            case 2:
                return 'icon-ok-circled text-gr-general f-20'
            case 201:
                return 'icon-cart-arrow-down text-gr-general f-20'
            case 202:
                return 'icon-shopping text-gr-general f-20'
            case 3:
                return 'icon-truck-fast-outline text-gr-general f-20'
            case 4:
                return 'icon-shopping text-gr-general f-20'
            case 25:
                return 'icon-playlist-remove text-danger f-20'
            case 26:
                return 'icon-account-remove-outline text-danger f-20'
            case 27:
                return 'icon-cancel-circled-outline text-danger f-20'
            case 31:
                return 'icon-cancel-circled-outline text-danger f-20'
            case 32:
                return 'icon-cancel-circled-outline text-danger f-20'
            default:
                return ''
            }
        },
        estado_pedido(estado){
            switch (estado){
            case 1:
                return 'Sin confirmar'
            case 2:
                return 'Confirmado'
            case 201:
                return 'Alistando'
            case 202:
                return 'Empacando'
            case 3:
                return 'Enviado...'
            case 4:
                return 'Entregado'
            case 26:
            case 27:
                return 'Cancelado'
            case 25:
                return 'Rechazado'
            case 31:
                return 'Reclamo'
            case 32:
                return 'Reclamo'
            default:
                return ''
            }
        },
        accion_filtro(obj){
            this.fecha_filtro = obj.fecha
            this.tipo_filtro = obj.tipo
            this.estados_filtro = obj.estados

            this.listar_pedidos(1)
        },
        openFiltro(){
            this.$refs.modalFiltro.open()
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{ background: #fff; }

/*-*-*-*-*-*-*-* Media Queries -*-*-*-*-*-*-*/
/*-*-*-*-*-*-*-* Largue Screens *-*-*-*-*-*-*/
@media (min-width : 1824px) {
    .menu-prinicipal-listar .listar-pedidos{
        width: 400px !important;
    }
}
@media(max-width:1000px){
    .menu-prinicipal-listar{
        display: none !important;
    }
    .select-responsive{
        display: block !important;
    }
}
@media(min-width:1000px) and (max-width : 1824px){
    .menu-prinicipal-listar .listar-pedidos{
        width: 365px !important;
    }
}
</style>
